<template>

<div>
<img :src="showingImgSrc">
<canvas ref="imgCamvas" style="posotion:absolute; display:none;" >
</canvas>
<br>
<hr>

<select @change="selectImg"  v-model="selected" class="form-control"   >
    <option  v-for="img in imgList " v-bind:key="img.src" :value="img.src">
               {{ img.text }}
 </option>
 </select>
        
</div>

</template>

<script>

import moment from "moment";
moment.tz.setDefault("Asia/Tokyo");
import { fetchFileWithCache } from "../../modules/webapi/file";

export default {
  name: 'ImageV4',
    props:[ "path"]  ,
  data (){
      return {
        client : "",
          showingImgSrc : "",
          selected : "",
          imgList : [] 
      };
  },
  async mounted(  ) {
  
//    this.$emit('show-loader',true); 
const loadingScreen = document.getElementById('loading-screen');
    loadingScreen.style.display = 'flex';
    this.client = WebDavClient.createCustomClient(
  process.env.VUE_APP_APP_API_V4_URL + "file/access/"
);   

           //
         await this.setImgList();
         loadingScreen.style.display = 'none';
    },
    methods:{
  
       async selectImg (){

                var buff  =  await this.client.getFileContents(this.selected);
            // const url =  d.base64.replace('data:application/octet-stream;','data:image/jpeg;');
            //const blob = new Blob([new Uint8Array(d)], { type: "application/octet-stream" });
                const blob = new Blob([buff]);
                const url = URL.createObjectURL(blob);
                
                var image = new Image();
                 image.onload = ()=>{
                        
                       const canvas = this.$refs.imgCamvas;
                       const ctx = canvas.getContext("2d");
                       // canvas.width =image.width;
                       // canvas.height =image.height;
                       ctx.drawImage(image , 0, 0);
                     this.showingImgSrc = url ;
                        
                    }
                image.src = url;
                
       },
       async setImgList(){
  
           await new Promise(resolve => setTimeout(resolve, 1000))
       
           const p=this.path.split('?');

           if(p.length !== 2 ){ return; }
      
           const param = new URLSearchParams(  "?" + p[1] );
 
         const name =param.get("name");
        const start = param.get("start");
        const end = param.get("end");
        
        var startDate = moment ( start ) ;
        var endDate = moment (end ) ;
        var loaded = false ;
       
        //this.$emit('show-loader',true);
        startDate.add(-1,'d');
        endDate.add(-1,'d');
    
        for (  ; startDate < endDate ; ){
          
         
          //  if (startDate == endDate){ continue; }
            const date =endDate.format('yyyy/MM/DD/');
            try{
                const basepath = "/camera/"+ name + "/" + date ;

                console.log(basepath);
                const directoryItems = await this.client.getDirectoryContents( basepath );
              
               // console.log(directoryItems);
                
                for ( var  d  = directoryItems.pop() ; d ; d = directoryItems.pop() ) {
                  if (d.basename.startsWith(".")) {
        continue; 
    }

                         var src =  basepath + d.basename ;
                        var text =   endDate.format('yyyy/MM/DD') +" "+ d.basename.replaceAll('-',':').split('.')[0]  ;
                        var opt = { src :src , text  :  text    } ;
                        
                        if (!loaded) {
                            this.selected = src;
                            await this.selectImg();
                            loaded = true;
                        }
                        this.imgList.push( opt );
                }
            }catch(e){
            
                console.log(e);
            }
            endDate.add(-1,'d');
            
        }
      
      }
    
    }
};

</script>